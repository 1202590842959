import request from '@/utils/axiosReq'
import { getForWorkOrder } from '@/utils/auth'

export function list_rules() {
  return {
    fast_add: {
      car_type: [{ required: true, message: '請選擇', trigger: 'blur' }],
      plate_number: [{ required: true, message: '請選擇', trigger: 'blur' }],
      engine_number: [{ required: true, message: '請選擇', trigger: 'blur' }],
      body_number: [{ required: true, message: '請選擇', trigger: 'blur' }]
    }
  }
}
export function formItems() {
  return {
    table_carlist: [
      {
        prop: 'plate_number',
        label: '車牌'
      },
      {
        prop: 'car_type',
        label: '車種'
      },
      {
        prop: 'brand',
        label: '品牌'
      },
      {
        prop: 'model_name',
        label: '車型名稱'
      },
      {
        prop: 'model_code',
        label: '車型代碼'
      },
      {
        prop: 'engine_number',
        label: '引擎號碼'
      },
      {
        prop: 'body_number',
        label: '車身號碼'
      },
      {
        prop: 'displacement',
        label: '排氣量'
      },
      {
        prop: 'displacement_sys_chk',
        label: '排氣量系統確認'
      },
      {
        prop: 'issuing_date',
        label: '發照日期'
      },
      {
        prop: 'issuing_date_sys_chk',
        label: '發照日期系統確認'
      },
      {
        prop: 'engine_stroke',
        label: '行程類別'
      },
      {
        prop: 'engine_stroke_sys_chk',
        label: '行程類別系統確認'
      },
      {
        prop: 'production_month',
        label: '製造年月'
      },
      {
        prop: 'production_month_sys_chk',
        label: '製造年月系統確認'
      },
      {
        prop: 'annual_inspection',
        label: '年檢日期'
      },
      {
        prop: 'annual_inspection_sys_chk',
        label: '年檢日期系統確認'
      },
      {
        prop: 'inspection_status',
        label: '檢驗狀態'
      },
      {
        prop: 'inspection_status_sys_chk',
        label: '檢驗狀態系統確認'
      },
      {
        prop: 'insurance_expiration_date',
        label: '保險到期日'
      },
      {
        prop: 'insurance_expiration_date_sys_chk',
        label: '保險到期日系統確認'
      },
      {
        prop: 'monthly_inspection_month',
        label: '每年定檢月份'
      },
      {
        prop: 'monthly_inspection_month_sys_chk',
        label: '每年定檢月份系統確認'
      }
    ],
    fast_add: [
      {
        prop: 'car_type',
        placeholder: '車種選擇',
        label: '車種選擇',
        rules: [{ required: true, message: '請選擇', trigger: 'blur' }],
        options: [
          {
            value: '機車',
            label: '機車'
          },
          {
            value: '汽車',
            label: '汽車'
          },
          {
            value: '電動機車',
            label: '電動機車'
          },
          {
            value: '電動汽車',
            label: '電動汽車'
          },
          {
            value: '電動自行車',
            label: '電動自行車'
          }
        ]
      },
      {
        prop: 'plate_number',
        placeholder: '車牌號碼',
        label: '車牌號碼',
        rules: [{ required: true, message: '請填寫', trigger: 'blur' }]
      },
      {
        prop: 'engine_number',
        placeholder: '引擎號碼',
        label: '引擎號碼',
        rules: [{ required: true, message: '請填寫', trigger: 'blur' }]
      },
      {
        prop: 'body_number',
        placeholder: '車身號碼',
        label: '車身號碼',
        rules: [{ required: true, message: '請填寫', trigger: 'blur' }]
      }
    ],
    add: [
      {
        prop: 'plate_number',
        placeholder: '車牌',
        label: '車牌'
      },
      {
        prop: 'car_type',
        placeholder: '車種',
        label: '車種'
      },
      {
        prop: 'brand',
        placeholder: '品牌',
        label: '品牌'
      },
      {
        prop: 'model_name',
        placeholder: '車型名稱',
        label: '車型名稱'
      },
      {
        prop: 'model_code',
        placeholder: '車型代碼',
        label: '車型代碼'
      },
      {
        prop: 'engine_number',
        placeholder: '引擎號碼',
        label: '引擎號碼'
      },
      {
        prop: 'body_number',
        placeholder: '車身號碼',
        label: '車身號碼',
        type: 'date'
      },
      {
        prop: 'issuing_date',
        placeholder: '發照日期',
        label: '發照日期',
        type: 'date'
      },
      {
        prop: 'issuing_date_sys_chk',
        placeholder: '發照日期系統確認',
        label: '發照日期系統確認'
      },
      {
        prop: 'stroke',
        placeholder: '行程',
        label: '行程'
      },
      {
        prop: 'stroke_sys_chk',
        placeholder: '行程系統確認',
        label: '行程系統確認'
      },
      {
        prop: 'engine_stroke',
        placeholder: '行程類別',
        label: '行程類別'
      },
      {
        prop: 'engine_stroke_sys_chk',
        placeholder: '行程類別系統確認',
        label: '行程類別系統確認'
      },
      {
        prop: 'production_month',
        placeholder: '製造年月',
        label: '製造年月',
        type: 'date'
      },
      {
        prop: 'production_month_sys_chk',
        placeholder: '製造年月系統確認',
        label: '製造年月系統確認'
      },
      {
        prop: 'annual_inspection',
        placeholder: '年度定檢',
        label: '年度定檢'
      },
      {
        prop: 'annual_inspection_sys_chk',
        placeholder: '年度定檢系統確認',
        label: '年度定檢系統確認'
      },
      {
        prop: 'inspection_status',
        placeholder: '檢驗狀態',
        label: '檢驗狀態'
      },
      {
        prop: 'inspection_status_sys_chk',
        placeholder: '檢驗狀態系統確認',
        label: '檢驗狀態系統確認'
      },
      {
        prop: 'insurance_expiration_date',
        placeholder: '保險到期日',
        label: '保險到期日',
        type: 'date'
      },
      {
        prop: 'insurance_expiration_date_sys_chk',
        placeholder: '保險到期日系統確認',
        label: '保險到期日系統確認'
      },
      {
        prop: 'monthly_inspection_month',
        placeholder: '每年定檢月份',
        label: '每年定檢月份',
        type: 'select',
        options: [
          { value: '1', label: '一月' },
          { value: '2', label: '二月' },
          { value: '3', label: '三月' },
          { value: '4', label: '四月' },
          { value: '5', label: '五月' },
          { value: '6', label: '六月' },
          { value: '7', label: '七月' },
          { value: '8', label: '八月' },
          { value: '9', label: '九月' },
          { value: '10', label: '十月' },
          { value: '11', label: '十一月' },
          { value: '12', label: '十二月' }
        ]
      },
      {
        prop: 'monthly_inspection_month_sys_chk',
        placeholder: '每年定檢月份系統確認',
        label: '每年定檢月份系統確認'
      }
    ]
  }
}

const loginStatus = JSON.parse(getForWorkOrder())
export function getVechicleExist(plate_number) {
  const _clean = transformString(plate_number)
  // console.log(plate_number)
  return request({
    url: '/Vechicle/getList',
    method: 'post',
    data: {
      whereCols: [
        {
          clean_plate_number: _clean
        }
      ]
    },
    bfLoading: false,
    isParams: false,
    isAlertErrorMsg: false
  })

  function transformString(input) {
    // 移除破折號
    input = input.replace(/-/g, '')

    // 將整個字串轉換為大寫
    input = input.toUpperCase()

    return input
  }
}
export function getEpa(inputData) {
  // console.log(inputData)
  return request({
    url: '/Setting/getEpaData/' + inputData,
    method: 'get',
    bfLoading: false,
    isParams: false,
    isAlertErrorMsg: false
  })
}
// 获取详情
export function search(keyword, page, items) {
  const _headquarterId = JSON.parse(localStorage.getItem('forWorkOrder'))?.headquarter_id ?? null

  if (!_headquarterId) {
    console.error('search headquarter_id is not found')
    return
  }

  return request({
    // url: '/Vechicle/search/',
    url: '/Vechicle/search_vehicle_and_contact',
    method: 'post',
    data: {
      keyword: keyword,
      page: page,
      items: items,
      headquarter_id: _headquarterId
    },
    isAlertErrorMsg: false
  })
}
// 新增
export function add(inputData) {
  console.log(inputData)
  return request({
    url: '/Vechicle/create',
    method: 'post',
    data: {
      plate_number: inputData.plate_number, //車牌
      car_type_id: inputData.car_type_id, //車種
      brand: inputData.brand, //品牌
      model_name: inputData.model_name, //車型名稱
      model_code: inputData.model_code, //車型代碼
      engine_number: inputData.engine_number, //引擎號碼
      body_number: inputData.body_number, //車身號碼
      stroke: inputData.stroke, //行程
      stroke_sys_chk: inputData.stroke_sys_chk, //行程系統確認
      engine_stroke: inputData.engine_stroke, //行程類別
      engine_stroke_sys_chk: inputData.engine_stroke_sys_chk, //行程類別系統確認
      issuing_date: inputData.issuing_date, //發照日期
      issuing_date_sys_chk: inputData.issuing_date_sys_chk, //發照日期系統確認
      production_month: inputData.production_month, //製造年月
      production_month_sys_chk: inputData.production_month_sys_chk, //製造年月系統確認
      annual_inspection: inputData.annual_inspection, //年度定檢
      annual_inspection_sys_chk: inputData.annual_inspection_sys_chk, //年度定檢系統確認
      inspection_status: inputData.inspection_status, //檢驗狀態
      inspection_status_sys_chk: inputData.inspection_status_sys_chk, //檢驗狀態系統確認
      insurance_expiration_date: inputData.insurance_expiration_date, //保險到期日
      insurance_expiration_date_sys_chk: inputData.insurance_expiration_date_sys_chk, //保險到期日系統確認
      monthly_inspection_month: inputData.monthly_inspection_month, //每年定檢月份
      monthly_inspection_month_sys_chk: inputData.monthly_inspection_month_sys_chk //每年定檢月份系統確認
    },
    bfLoading: false,
    isParams: false
    // isAlertErrorMsg: false
  })
}

// 修改
export function edit(inputData) {
  return request({
    url: '/Vechicle/update/' + inputData.id,
    method: 'post',
    data: {
      plate_number: inputData.plate_number, //車牌
      displacement: inputData.displacement,
      displacement_sys_chk: inputData.displacement_sys_chk,
      car_type_id: inputData.car_type_id, //車種
      color: inputData.color,
      brand: inputData.brand, //品牌
      model_name: inputData.model_name, //車型名稱
      model_code: inputData.model_code, //車型代碼
      engine_number: inputData.engine_number, //引擎號碼
      body_number: inputData.body_number, //車身號碼
      issuing_date: inputData.issuing_date, //發照日期
      issuing_date_sys_chk: inputData.issuing_date_sys_chk, //發照日期系統確認
      stroke: inputData.stroke, //行程
      stroke_sys_chk: inputData.stroke_sys_chk, //行程系統確認
      engine_stroke: inputData.engine_stroke, //行程類別
      engine_stroke_sys_chk: inputData.engine_stroke_sys_chk, //行程類別系統確認
      production_month: inputData.production_month, //製造年月
      production_month_sys_chk: inputData.production_month_sys_chk, //製造年月系統確認
      annual_inspection: inputData.annual_inspection, //年度定檢
      annual_inspection_sys_chk: inputData.annual_inspection_sys_chk, //年度定檢系統確認
      inspection_status: inputData.inspection_status, //檢驗狀態
      inspection_status_sys_chk: inputData.inspection_status_sys_chk, //檢驗狀態系統確認
      insurance_expiration_date: inputData.insurance_expiration_date, //保險到期日
      insurance_expiration_date_sys_chk: inputData.insurance_expiration_date_sys_chk, //保險到期日系統確認
      monthly_inspection_month: inputData.monthly_inspection_month, //每年定檢月份
      monthly_inspection_month_sys_chk: inputData.monthly_inspection_month_sys_chk //每年定檢月份系統確認
    },
    bfLoading: false,
    isParams: false,
    isAlertErrorMsg: false
  })
}

export function getOnce(inputData) {
  // console.log(inputData)
  return request({
    url: '/Vechicle/getOnce/' + inputData,
    method: 'post',
    bfLoading: false,
    isParams: false,
    isAlertErrorMsg: false
  })
}

export function getList(inputData) {
  return request({
    url: '/Headquarter/list',
    method: 'post',
    data: {
      selectCols: inputData.selectCols == undefined ? '' : inputData.selectCols, //選擇欄位
      sortCols: inputData.sortCols == undefined ? '' : inputData.sortCols, //排序欄位 ex: headquarterId desc
      findCols: inputData.findCols == undefined ? '' : inputData.findCols //Object	查詢欄位
    },
    bfLoading: false,
    isParams: false,
    isAlertErrorMsg: false
  })
}
// 方案設定
export function planSetting(headquarterId, inputData) {
  return request({
    url: '/Headquarter/setHeadquarterPlan/' + headquarterId,
    method: 'post',
    data: {
      planId: inputData.planId, //方案編號
      managerId: inputData.managerId, //管理者編號
      planStartDate: inputData.planStartDate, //方案開始日期，如果是空值，就是立即生效
      planEndDate: inputData.planEndDate, //方案結束日期，如果是空值就直接抓取 plan 的結束日期
      planAuthCountLimit: inputData.planAuthCountLimit //授權數量上限,如果是空值就直接抓取 plan 的數量上限
    }
  })
}
// 删除
export function del(headquarterId) {
  return request({
    url: '/Headquarter/delete/' + headquarterId,
    method: 'post'
  })
}
// 获取详情
export function getDetail(id) {
  return request({
    url: '/Headquarter/detail/' + id,
    method: 'get'
  })
}

// 設定總部服務的 manager
export function assignManager(inputData) {
  return request({
    url: '/Headquarter/assignManager/' + inputData.headquarterId,
    method: 'post',
    data: {
      headquarterId: inputData.headquarterId, //總部編號
      managerId: inputData.managerId //管理員編號
    }
  })
}

// 總部服務的account:建立
export function createAccount(inputData) {
  return request({
    url: '/Headquarter/createHeadquarterAccount',
    method: 'post',
    data: {
      headquarterId: inputData.headquarterId, //String	 總部編號
      headquarterAccount: inputData.headquarterAccount, //String	 帳號
      headquarterPassword: inputData.headquarterPassword, //String	 密碼
      level: inputData.level //String	 權限等級 0:總部管理者 1:總部服務人員
    }
  })
}

// 總部服務的account:指派
export function settingAccount(inputData) {
  return request({
    url: '/Headquarter/updateHeadquarterAccount/' + inputData,
    method: 'post',
    data: {
      headquarterAccount: inputData
    }
  })
}

// 總部服務的account:取得
export function getAccount(inputData) {
  return request({
    url: '/Headquarter/getHeadquarterAccount/' + inputData
  })
}

// 總部服務的account:修改
// export function createAccount(inputData) {
//   return request({
//     url: '/Headquarter/updateHeadquarterAccount/' + inputData,
//   })
// }

// 部品更換狀態
export function getPartsReplacementStatus(data) {
  return request({
    url: '/Vechicle/openup/parts_replacement_status',
    method: 'post',
    data
  })
}
// 工單列表
export function getWorkOrderList(data) {
  return request({
    url: '/Vechicle/openup/work_order_list',
    method: 'post',
    data
  })
}
