<template>
  <div :class="classObj" class="layout-wrapper">
    <Navbar v-if="settings.showTopNavbar" />
    <!--right container-->
    <div id="main-right" class="main-container">
      <div class="sidebar-container">
        <div class="sidebar-scroll-wrapper">
          <el-scrollbar id="leftScroll">
            <Sidebar
              v-if="settings.showLeftMenu && _isManager"
              :managerTF="_isManager.branchManager"
            />
          </el-scrollbar>
        </div>

        <div class="switchBtn" style="cursor:pointer" @click="toggleSideBar">
          <span class="pl-5 pr-5">
            <template v-if="isMenuOpen">
              <i class="fa-solid fa-eye-slash"></i>
            </template>
            <template v-else>
              <i class="fa-solid fa-eye"></i>
            </template>
          </span>
          <span v-show="isMenuOpen" style="font-size:0.9rem;line-height:56px">開闔選單</span>
        </div>

        <!-- <div id="sideBarOpenArea" @click.prevent="toggleSideBar" class="group">
          <template v-if="appStore.sidebar.opened">
            <el-icon class="opacity-0 group-hover:opacity-100 transition duration-300">
              <ArrowLeft />
            </el-icon>
          </template>
          <template v-else>
            <el-icon><ArrowRight /></el-icon>
          </template>
        </div> -->
      </div>
      <!--left side-->
      <!-- <hamburger
        v-if="settings.showHamburger"
        :is-active="opened"
        class="hamburger-container"
        @toggleClick="toggleSideBar"
      /> -->

      <!-- 舊版樣式配置 -->
      <!-- <div class="sidebar-container">
        <el-scrollbar height="calc(100dvh-70px)">
          <Sidebar
            v-if="settings.showLeftMenu && _isManager"
            :managerTF="_isManager.branchManager"
          />
        </el-scrollbar>

        <div id="sideBarOpenArea" @click.prevent="toggleSideBar" class="group">
          <template v-if="appStore.sidebar.opened">
            <el-icon class="opacity-0 group-hover:opacity-100 transition duration-300"
              ><ArrowLeft
            /></el-icon>
          </template>
          <template v-else>
            <el-icon><ArrowRight /></el-icon>
          </template>
        </div>
      </div> -->

      <TagsView v-if="settings.showTagsView" />
      <AppMain />
    </div>
  </div>
</template>

<script setup name="Layout">
import { Sidebar, Navbar, AppMain, TagsView } from './components'
import { useUserStore } from '@/store/user'
//import ResizeHook to   listen  page size that   open or close
import ResizeHook from './hook/ResizeHandler'
import { useAppStore } from '@/store/app'
import { ArrowLeft, ArrowRight ,Switch } from '@element-plus/icons-vue'
import { getIsManager } from '@/utils/auth'
import { onMounted } from 'vue'


ResizeHook()

const _isManager = ref(null);
// console.log("222")
const appStore = useAppStore()

const opened = computed(() => {
  return appStore.sidebar.opened
})
const settings = computed(() => {
  return appStore.settings
})
const classObj = computed(() => {
  return {
    closeSidebar: !opened.value,
    hideSidebar: !settings.value.showLeftMenu
  }
})
const isMenuOpen = ref(true);
const toggleSideBar = () => {
  appStore.sidebar.opened = !appStore.sidebar.opened
  // 打開前檢查一下是否是店長，傳給Sidebar
  _isManager.value = JSON.parse(getIsManager())
  // console.log(_isManager.value)
}

watch(() => appStore.sidebar.opened, (newValue,oldValue) => {
    isMenuOpen.value = newValue;
  },
  { immediate: true }
);
onMounted(()=>{
  setTimeout(() => {
    _isManager.value = JSON.parse(getIsManager());
  }, 400);
})
</script>

<style lang="scss" scoped>
.main-container {
  min-height: 100%;
  transition: margin-left 0.28s;
  margin-left: var(--side-bar-width);
  position: relative;
  border-left: 1px solid var(--layout-border-left-color);
}
.sidebar-container {
  transition: width 0.28s;
  width: var(--side-bar-width) !important;
  background-color: var(--el-menu-bg-color);
  height: 100%;
  position: fixed;
  font-size: 0;
  // top: 0;
  top: var(--nav-bar-height);
  left: 0;
  // z-index: 1001;
  overflow: hidden;
}
.el-menu-item{
  margin-right: 10px !important;
}
.closeSidebar {
  // .sidebar-container {
  //   width: var(--close-sidebar-width) !important;
  //   left: calc(-1 * var(--close-sidebar-width));
  // }
  .sidebar-container {
    width: 54px !important;
  }
  .main-container {
    margin-left: 54px !important;
  }
  // #sideBarOpenArea {
  //   width: var(--close-sidebar-width) !important;
  //   left: 0;
  //   background-color: var(--el-menu-bg-color);
  // }
}
.hideSidebar {
  .sidebar-container {
    width: 0 !important;
  }
  .main-container {
    margin-left: 0;
  }
}
.switchBtn{
    width: 100%;
    height: 56px;
    line-height: 56px;
    color: #bfcbd9;
    z-index: 2222222222;
    position: relative;
    font-size: 1rem;
}
.sidebar-scroll-wrapper {
  // height: calc(100vh - var(--nav-bar-height));
  height: calc(100vh - 18vh)!important;
}
</style>
<style>

#leftScroll{
  overflow-x: hidden;
}
</style>