import request from '@/utils/axiosReq'
import { getForWorkOrder } from '@/utils/auth'

// 從 localStorage 取得登入者資訊
// let loginStatus = null
// try {
//   const workOrderData = getForWorkOrder()
//   loginStatus = workOrderData ? JSON.parse(workOrderData) : null
// } catch (error) {
//   console.error('Failed to parse work order data:', error)
//   loginStatus = null // 解析失敗時，設置為 null
// }

// 取回 ERP 各分店功能細部設定
export function getErpBranchConfig(data) {
  return request({
    url: '/ErpConfig/get_erp_branch_config',
    method: 'post',
    data
  })
}
// 設定 ERP 各分店功能細部設定
export function setErpBranchConfig(data) {
  return request({
    url: '/ErpConfig/set_erp_branch_config',
    method: 'post',
    data
  })
}

// 出/入庫 類別設定
// 取得 入庫 類別
export function getErpEntryReasonTypes(data) {
  return request({
    url: '/ErpConfig/get_erp_instock_types',
    method: 'post',
    data
  })
}
// 設定 入庫 類別
export function setErpEntryReasonTypes(data) {
  return request({
    url: '/ErpConfig/set_erp_instock_types',
    method: 'post',
    data
  })
}

// 取得 出庫 類別
export function getErpOutReasonTypes(data) {
  return request({
    url: '/ErpConfig/get_erp_outstock_types',
    method: 'post',
    data
  })
}

// 設定 出庫 類別
export function setErpOutReasonTypes(data) {
  return request({
    url: '/ErpConfig/set_erp_outstock_types',
    method: 'post',
    data
  })
}

// 取得 安全庫存
export function getErpSafetyStock(data) {
  return request({
    url: '/ErpConfig/get_erp_safety_stock',
    method: 'post',
    data
  })
}

// 設定 安全庫存
export function setErpSafetyStock(data) {
  return request({
    url: '/ErpConfig/set_erp_safety_stock',
    method: 'post',
    data
  })
}

// 取得供應商
export function getErpCommonSupplier(data) {
  return request({
    url: '/ErpConfig/get_erp_supplier',
    method: 'post',
    data
  })
}
// 設定供應商
export function setErpCommonSupplier(data) {
  return request({
    url: '/ErpConfig/set_erp_supplier',
    method: 'post',
    data
  })
}

// 出庫對象設定
export function getErpCommonCustomer(data) {
  return request({
    url: '/ErpConfig/get_erp_customer',
    method: 'post',
    data
  })
}

// 設定 出庫對象
export function setErpCommonCustomer(data) {
  return request({
    url: '/ErpConfig/set_erp_customer',
    method: 'post',
    data
  })
}

// 確認 ERP 功能開啟權限
export function getErpAuthStatus() {
  return request({
    url: '/ErpConfig/auth_status',
    method: 'get',
    isAlertErrorMsg: false
  })
}
