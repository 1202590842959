<template>
  <!-- 快速新增車籍資料 -->
  <div>
    <el-dialog
      :model-value="isOpen"
      :show-close="true"
      @close="onEmit"
      :width="smallerThanLg ? '90%' : '40%'"
    >
      <template #header="{ close, titleId, titleClass }">
        <div class="my-header text-center">
          <p class="display-4 mb-0" :id="titleId" :class="titleClass" style="font-weight: 700">
            快速新增車籍資料
          </p>
          <!-- <p class="text-danger">*以下三項需至少填寫一項，才可快速新增車籍資料</p> -->
        </div>
      </template>
      <!-- class="inline-form" -->
      <el-form
        label-width="90px"
        :label-position="smallerThanLg ? 'top' : 'right'"
        :model="formLabelAlign"
        ref="formRef"
        :rules="rules"
        @submit.prevent="submitForm"
      >
        <el-form-item label="車種選擇" prop="car_type_id">
          <el-select
            placeholder="請選擇維修車種"
            style="width: 100%"
            v-model="formLabelAlign.car_type_id"
          >
            <el-option
              :label="car.name"
              :value="car.id"
              v-for="(car, idx) in carType"
              :key="idx"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-divider />
        <el-form-item label="車牌號碼" prop="plate_number">
          <el-input v-model="formLabelAlign.plate_number" class="text-uppercase" />
        </el-form-item>
        <el-form-item label="引擎號碼" prop="engine_number">
          <el-input v-model="formLabelAlign.engine_number" class="text-uppercase" />
        </el-form-item>
        <el-form-item label="車身號碼" prop="body_number">
          <el-input v-model="formLabelAlign.body_number" class="text-uppercase" />
        </el-form-item>
        <el-form-item label="">
          <el-row :gutter="10" style="width: 100%">
            <el-col :span="12">
              <el-button size="large" style="width: 100%" @click.prevent="onEmit">取消</el-button>
            </el-col>
            <el-col :span="12">
              <el-button size="large" style="width: 100%" type="primary" native-type="submit"
                >新增</el-button
              >
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script setup>
import { toRefs, reactive } from 'vue'
import { ElButton, ElDialog, ElMessage } from 'element-plus'
import { getListType } from '@/api/cars'
import { add } from '@/api/vechicle'
import { smallerThanLg } from '@/config/breakpoints'

const router = useRouter()
const emits = defineEmits(['go-close'])

const onEmit = () => {
  emits('go-close', 'plate_number_add')
}
const props = defineProps(['isOpen', 'params'])
const formLabelAlign = ref({
  car_type_id: '',
  plate_number: '',
  engine_number: '',
  body_number: ''
})
const carType = ref(null)
getListType()
  .then((res) => {
    if (res.sysCode == 200) {
      // console.log(res.data)
      carType.value = res.data
    }
  })
  .catch((err) => {
    console.error('getListType Error ===>', err)
    // ElMessage.error('getListType' + err.data)
  })
const checkAtLeastOneFilled = (rule, value, callback) => {
  const plateNumberLength = formLabelAlign.value.plate_number
    ? formLabelAlign.value.plate_number.length
    : 0
  const engineNumberLength = formLabelAlign.value.engine_number
    ? formLabelAlign.value.engine_number.length
    : 0
  const bodyNumberLength = formLabelAlign.value.body_number
    ? formLabelAlign.value.body_number.length
    : 0
  const _lengthTooShort = plateNumberLength < 8 && engineNumberLength < 8 && bodyNumberLength < 8 // 三個車牌長度檢查
  const _atLeastNotFill =
    !value &&
    !formLabelAlign.value.plate_number &&
    !formLabelAlign.value.engine_number &&
    !formLabelAlign.value.body_number //三擇一填寫檢查

  if (_lengthTooShort && _atLeastNotFill) {
    callback(new Error('引擎號碼、車身號碼、車牌號碼至少填寫其中一項且值的長度需大於7個字元'))
  } else {
    callback()
  }
}
// const formRules = list_rules()['fast_add']
const rules = ref({
  car_type_id: [{ required: true, message: '請選擇車種', trigger: 'blur' }],
  plate_number: [
    { required: true, message: '請輸入車牌號碼', trigger: 'blur' },
    {
      required: true,
      message: '車牌號碼長度不能超過10個字元',
      trigger: 'blur',
      validator: (rule, value, callback) => {
        if (value.length > 10) {
          callback(new Error('車牌號碼長度不能超過10個字元'))
        } else {
          callback()
        }
      }
    }
  ]
  // engine_number: [
  //   { required: false, message: '請輸入引擎號碼', trigger: 'blur' },
  //   { validator: checkAtLeastOneFilled , trigger: 'blur' }
  // ],
  // body_number: [
  //   { required: false, message: '請輸入車身號碼', trigger: 'blur' },
  //   { validator: checkAtLeastOneFilled , trigger: 'blur' }
  // ]
})
const _routerQuery = ref({}) //為了預約來的人，儲存要帶過去工單的參數
const formRef = ref({})
const submitForm = () => {
  // return new Promise((resolve, reject) => {
  formRef.value.validate((valid) => {
    formLabelAlign.value['plate_number'] = formLabelAlign.value['plate_number']
      .replace(/\s+/g, '')
      .toUpperCase()
    if (valid) {
      // console.log(formLabelAlign.value)
      add(formLabelAlign.value)
        .then((res) => {
          ElMessage.success('成功')
          // console.log(res)
          let _query = res.data
          _query['mileage'] = 'open'
          // const mergedObject = { ..._routerQuery.value, ...res.data };
          // console.log('mergedObject',mergedObject)
          if (_routerQuery.value.reserve_id) {
            // 把預約的參數還回去
            emits('go-close', res.data)
          } else {
            onEmit()
            setTimeout(() => {
              router.push({
                path: '/repair/car_detail',
                // query: mergedObject
                query: res.data
              })
            }, 500)
          }
        })
        .catch((err) => {
          console.log('submitForm Error ===>', err)
        })
    } else {
      console.log('!d')
      // reject(new Error('表單驗證不通過'));
    }
  })
}
watch(
  () => props.isOpen,
  async () => {
    const isPrarmsExist = props.params ? true : false //判斷是否有多參數，有：目前來自預約中>>新建工單
    if (isPrarmsExist) {
      const isReserveExist = Object.keys(props.params.reserve).length > 0 ? true : false //判斷是否有多參數，有：目前來自預約中>>新建工單
      if (isReserveExist) {
        formLabelAlign.value.plate_number = props.params.reserve.plate_number
        _routerQuery.value.reserve_id = props.params.reserve.reserve_id
      }
    }
    // if( props.params.reserve ){
    //   const reserveData = props.params.reserve;
    //   console.log('reserveData', reserveData)
    //   formLabelAlign.value.plate_number = reserveData.plateNumber;
    // }else{
    //   console.log('no reserveData')
    // }
  },
  { immediate: true }
)
</script>

<style lang="scss" scoped>
.dashboard-editor-container {
  padding: 32px;
  // background-color: rgb(240, 242, 245);
  position: relative;

  .github-corner {
    position: absolute;
    top: 0;
    border: 0;
    right: 0;
  }

  .chart-wrapper {
    background: #fff;
    padding: 16px 16px 0;
    margin-bottom: 32px;
  }
}

@media (max-width: 1024px) {
  .chart-wrapper {
    padding: 8px;
  }
}
</style>
