import request from '@/utils/axiosReq'

export function formItems(){
  return {
    getList : {
      brand : [
        { 
          label : "ID" , 
          prop : "id" 
        },
        { 
          label : "品牌" , 
          prop : "name"
        }
      ],
      type : [
        { 
          label : "ID" , 
          prop : "id" 
        },
        { 
          label : "車種" , 
          prop : "name"
        }
      ]
    },
    add : {
      brand : [
        {
          prop : "id",// String	Manager Id
          placeholder : "ID",
          label : "ID",
          disabled : true
        },
        {
          prop : "name",// String	Manager Id
          placeholder : "品牌",
          label : "品牌",
        },
      ],
      type : [
        {
          prop : "id",// String	Manager Id
          placeholder : "ID",
          label : "ID",
          disabled : true
        },
        {
          prop : "name",// String	Manager Id
          placeholder : "車種",
          label : "車種",
        },
      ]
    },
    edit : {
      brand : [
        {
          prop : "id",// String	Manager Id
          placeholder : "ID",
          label : "ID",
          disabled : true
        },
        {
          prop : "name",// String	Manager Id
          placeholder : "品牌",
          label : "品牌",
        },
      ],
      type : [
        {
          prop : "id",// String	Manager Id
          placeholder : "ID",
          label : "ID",
          disabled : true
        },
        {
          prop : "name",// String	Manager Id
          placeholder : "車種",
          label : "車種",
        },
      ]
    }
  }
}

// 管理員登入
export function LoginMangerReq(data) {
  return request({
    url: '/Manager/login/',
    data,
    method: 'post',
    bfLoading: false,
    isParams: false,
    isAlertErrorMsg: false
  })
}

export function getInfoReq() {
  return request({
    url: '/Manager/findOneByToken/',
    method: 'get',
    bfLoading: false,
    isParams: false,
    isAlertErrorMsg: false
  })
}

export function getListBrand(){
  return request({
    url: '/Setting/getCarBrand',
    method: 'get',
    bfLoading: false,
    isParams: false,
    isAlertErrorMsg: false
  })
}
export function getListType(){
  return request({
    url: '/Setting/getCarType',
    method: 'get',
    bfLoading: false,
    isParams: false,
    isAlertErrorMsg: false
  })
}

// 新增
export function toggleSetBrand(json) {
  return request({
    url: '/Setting/setCarBrand',
    method: 'post',
    data : json
  })
}
export function toggleSetType(json) {
  return request({
    url: '/Setting/setCarType',
    method: 'post',
    data : json
  })
}