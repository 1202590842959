import Layout from '@/layout'

const ERPRouter = {
  path: '/ERP',
  component: Layout,
  redirect: '/ERP/dashboard',
  hidden: false,
  meta: {
    title: '進銷存系統',
    fontAwesome : "fa-solid fa-gear"
  },
  children: [
    // 儀表板 - Dashboard
    // 採購管理 - PurchaseManagement
    // 入庫管理 - GoodsReceiving
    // 庫存管理 - InventoryManagement
    // 出庫管理 - GoodsDispatch
    // 銷貨管理 - SalesManagement
    // 系統設定 - SystemSettings
    {
      path: 'dashboard',
      component: () => import('@/views/ERP/Dashboard.vue'),
      name: 'ERPDashboard',
      meta: { title: '儀表板' }
    },
    {
      path: 'purchase-management',
      component: () => import('@/views/ERP/PurchaseManagement.vue'),
      name: 'ERPPurchaseManagement',
      meta: { title: '採購管理' }
    },
    {
      path: 'goods-receiving',
      component: () => import('@/views/ERP/GoodsReceiving.vue'),
      name: 'ERPGoodsReceiving',
      meta: { title: '入庫管理' }
    },
    {
      path: 'inventory-management',
      component: () => import('@/views/ERP/InventoryManagement.vue'),
      name: 'ERPInventoryManagement',
      meta: { title: '庫存管理' }
    },
    {
      path: 'goods-dispatch',
      component: () => import('@/views/ERP/GoodsDispatch.vue'),
      name: 'ERPGoodsDispatch',
      meta: { title: '出庫管理' }
    },
    {
      path: 'sales-management',
      component: () => import('@/views/ERP/SalesManagement.vue'),
      name: 'ERPSalesManagement',
      meta: { title: '銷貨管理' }
    },
    {
      path: 'system-settings',
      component: () => import('@/views/ERP/SystemSettings.vue'),
      name: 'ERPSystemSettings',
      meta: { title: '系統設定' }
    }
  ]
}

export default ERPRouter
