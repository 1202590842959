import Layout from '@/layout'
const reserveRouter = {
  path: '/reserve',
  component: Layout,
  redirect: '/reserve/setting',
  name: 'setting',
  meta: {
    title: '預約管理',
    fontAwesome : 'fa-regular fa-calendar-check'
  },
  children: [
    {
      path: 'setting',
      component: () => import('@/views/reserve/setting.vue'),
      name: 'ReserveSetting',
      meta: { title: '預約設定' }
    },
    {
      path: 'list',
      component: () => import('@/views/reserve/list.vue'),
      name: 'ReserveList',
      meta: { title: '預約詳情' }
    },
    {
      path: 'status',
      component: () => import('@/views/reserve/status.vue'),
      name: 'ReserveStatus',
      meta: { title: '預約狀態' }
    }
  ]
}

export default reserveRouter
